import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
height:${props => props.mobileHeight}px;
@media(min-width:983px){
    height:${props => props.height}px;
}
`

const Spacer = (props) => {
    const height = props.height==null?(200):(props.height);
    const mobileHeight = props.mobileHeight==null?(height/2):(props.mobileHeight);

    return(
        <Container height={height} mobileHeight={mobileHeight}/>
    )
}

Spacer.propTypes = {
    height: PropTypes.number,
    mobileHeight: PropTypes.number,
}

export default Spacer;